<script setup lang="ts">
    import { defineProps, computed, useAttrs, useSlots } from 'vue';

    const attrs = useAttrs();

    const slots = useSlots();

    const props = defineProps({
        buttonText: {
            type: String,
            required: false,
            default: ''
        },
        openLink: {
            type: String,
            required: false,
            default: ''
        },
        iconClass: {
            type: String,
            required: false,
            default: ''
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'bg-white'
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        badgeClass: {
            type: String,
            required: false,
            default: ''
        },
        showIconStatus: {
            type: Boolean,
            required: false,
            default: false
        },
        iconStatusState: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    const buttonClass = computed(() => {
        let cursorClass = '';
        if (attrs && attrs.onClick && !props.isDisabled) {
            cursorClass = 'cursor-pointer';
        }

        const classList = [
            'position-relative',
            'd-flex',
            'border',
            'rounded',
            'shadow-sm',
            'align-items-center',
            'justify-content-center',
            cursorClass,
            props.isDisabled,
            props.backgroundColor,
        ];
        
        return classList.join(' ');
    });
    
    const buttonBadgeClass = computed(() => {
        const classList = [
            'px-2',
            'py-1',
            'badge',
            'border',
            'd-flex',
            'fw-bold',
            'bg-primary',
            'position-absolute',
            'align-items-center',
            'justify-content-center',
            props.badgeClass
        ];
        
        return classList.join(' ');
    });

    const buttonBadgeStyle = computed(() => {
        // Setting a very large border-radius px value results in a pill-shape instead of more circular all over
        return `
            top: -10px;
            right: -10px;
            border-radius: 500px;
        `;
    });

    const buttonContentClass = computed(() => {
        const classList = [
            'p-2',
            'gap-2',
            'd-flex',
            'fw-semibold',
            'no-decoration',
            'align-items-center',
            'justify-content-center',
            (!attrs || !attrs.onClick) && props.openLink === '' ? 'text-black' : ''
        ];
        
        return classList.join(' ');
    });

    const buttonIconClass = computed(() => {
        const classList = [
            'position-relative',
            'bi',
            props.iconClass
        ];
        
        return classList.join(' ');
    });

    const buttonIconStatusClass = computed(() => {
        const classList = [
            'p-1',
            'top-0',
            'border',
            'start-100',
            'border-light',
            'rounded-circle',
            'translate-middle',
            'position-absolute',
            props.iconStatusState ? 'bg-success' : 'bg-danger'
        ];
        
        return classList.join(' ');
    });
</script>

<template>
    <div :class="buttonClass">
        <span v-if="$slots.badgeContent" :class="buttonBadgeClass" :style=buttonBadgeStyle>
            <slot name="badgeContent"></slot>
        </span>

        <component v-if="!$slots.buttonContent" :is="isDisabled ? 'span' : 'a'" :class="buttonContentClass" :href="openLink === '' ? null : openLink" target="openLink === '' ? null : '_blank'">
            <i v-if="iconClass !== ''" :class="buttonIconClass">
                <span v-if="showIconStatus" :class=buttonIconStatusClass></span>
            </i>
            <span>{{ buttonText }}</span>
        </component>

        <span v-if="$slots.buttonContent" :class="buttonContentClass">
            <slot name="buttonContent"></slot>
        </span>
    </div>
</template>

<style scoped>
    a.no-decoration {
        text-decoration: none;
    }
    a.no-decoration:hover, a.no-decoration:focus, a.no-decoration:active, a.no-decoration:visited {
        text-decoration: none;
    }
    .disabled {
        cursor: unset;
        opacity: 70%;
    }
    .cursor-pointer {
        cursor: pointer;
    }
</style>